import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { scroller } from "react-scroll";
import before_after from '../../assets/images/before_after.png';
import before_after_1 from '../../assets/images/before_after_1.png';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            overflow: 'hidden',

            "& h4": {
                fontFamily: "Raleway",
                textTransform: "uppercase",
                marginTop: "60px",
            },
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        card: {
            transition: 'all .2s ease-in-out',
            width: '575px',
            height: '950px',
            '&:hover': {
                transform: 'scale(1.08)',
            },
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        divider: {
            width: '40px',
            border: 0,
        },
        expandButton: {
            marginTop: "20px",
            color: "#4a4a4a",
            zIndex: 2,
        },
    }),
);


export const Gallery: React.FC = () => {
    const classes = useStyles();

    const handleExpandClick = () => {
        scroller.scrollTo("contact", {
            duration: 1500,
            delay: 100,
            smooth: true,
        });
    };

    return (
        <div id='gallery' className={classes.root}>
            <Typography variant='h4' style={{ color: '#4a4a4a' }}>
                Gallery
      </Typography>
            <hr
                className={classes.divider}
                style={{ borderTop: '2px solid #653A96' }}
            />
            <Grid
                container
                direction={'row'}
                justify='center'
                alignContent='center'
                spacing={8}
                style={{ marginTop: '30px', marginBottom: '10px', width: '100%' }}>
                <Grid item>
                    <Paper elevation={3} className={classes.card}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <img
                                alt='before and after cleaning'
                                src={before_after}
                            />
                        </div>
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper elevation={3} className={classes.card}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <img
                                alt='before and after cleaning'
                                src={before_after_1}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <IconButton
                className={classes.expandButton}
                onClick={handleExpandClick}>
                <ExpandMore fontSize="large" />
            </IconButton>
        </div >
    );
}