import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { scroller } from "react-scroll";
import cover from "../../assets/images/contact_pattern.png";
import gmail from "../../assets/contact/gmail.png";
import facebook from "../../assets/contact/facebook.png";
import { ExpandLess } from "@material-ui/icons";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#cccccc",

        "& h4": {
            fontFamily: "Raleway",
            textTransform: "uppercase",
            marginTop: "60px",
        },
        "& h5": {
            fontFamily: "Raleway",
            textTransform: "uppercase",
            padding: "10px",
        },
    },

    divider: {
        width: "40px",
        border: 0,
    },
    contactContainer: {
        width: "100%",
        backgroundImage: "url(" + cover + ")",
        backgroundPosition: "center center",
        height: "500px",
        boxShadow:
            "inset 0px 11px 8px -5px rgba(0,0,0,0.6),inset 0px -11px 8px -5px rgba(0,0,0,0.6)",
        "& img": {
            transition: "all .2s ease-in-out",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            margin: "20px",
            borderRadius: 200,
            "&:hover": {
                backgroundColor: "#653A96",
            },
        },
    },

    contactButton: {
        transition: "all .2s ease-in-out",
        backgroundColor: "transparent",
        margin: "20px",
        "&:hover": {
            backgroundColor: "#653A96",
            color: "white",
        },
    },
});

export const Contact: React.FC = () => {
    const classes = useStyles();

    const handleTopClick = () => {
        scroller.scrollTo("home", {
            duration: 1000,
            delay: 100,
            smooth: true,
        });
    };

    return (
        <div id="contact" className={classes.root}>
            <Grid
                container
                direction="column"
                xs={12}
                alignItems="center"
                style={{ backgroundColor: "#cccccc", width: "100%" }}
            >
                <Typography variant="h4" style={{ color: "#4a4a4a" }}>
                    Contact
				</Typography>
                <hr
                    className={classes.divider}
                    style={{ borderTop: "2px solid #653A96" }}
                ></hr>
            </Grid>

            <Typography
                style={{
                    color: "#4a4a4a",
                    padding: "20px",
                    width: "50%",
                    textAlign: "center",
                }}
            >
                Want to see more or get in touch? Feel free to check out any of the
                below sites!
			</Typography>

            <Grid
                container
                direction={"row"}
                justify="center"
                xs={12}
                style={{ padding: "120px" }}
                className={classes.contactContainer}
            >
                <a
                    href="https://www.facebook.com/TonyPowerChimneyCleaning"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        width={200}
                        height={200}
                        src={facebook}
                        alt={"facebook"}
                    />
                </a>

                <a
                    href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=tonypowerchimneys@gmail.com&tf=1"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        width={200}
                        height={200}
                        src={gmail}
                        alt={"gmail"}
                    />
                </a>
            </Grid>

            <hr
                className={classes.divider}
                style={{ borderTop: "2px solid #4a4a4a" }}
            />

            <div
                style={{
                    display: "flex",
                    flex: "1 0 auto",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >


                <Button
                    onClick={handleTopClick}
                    style={{ margin: "20px" }}
                    startIcon={<ExpandLess fontSize="large" />}
                >
                    Back To Top
				</Button>

            </div>
        </div>
    );
};
