import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { scroller } from "react-scroll";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#cccccc",
        "& h4": {
            fontFamily: "Raleway",
            textTransform: "uppercase",
            marginTop: "60px",
        },
        "& h5": {
            fontFamily: "Raleway",
            textTransform: "uppercase",
            padding: "10px",
            textAlign: "center",
        },
    },
    divider: {
        width: "40px",
        border: 0,
    },

    expandButton: {
        marginTop: "20px",
        color: "#4a4a4a",
        zIndex: 2,
    },
});

export const About: React.FC = () => {
    const classes = useStyles();

    const handleExpandClick = () => {
        scroller.scrollTo("services", {
            duration: 1500,
            delay: 100,
            smooth: true,
        });
    };

    return (
        <div id="about" className={classes.root}>
            <Grid
                container
                direction="column"
                xs={12}
                alignItems="center"
                style={{ backgroundColor: "#dedede", width: "100%" }}
            >
                <Typography variant="h4" style={{ color: "#4a4a4a" }}>
                    About
				</Typography>
                <hr
                    className={classes.divider}
                    style={{ borderTop: "2px solid #653A96" }}
                />

                <Typography
                    style={{
                        color: "#4a4a4a",
                        padding: "20px",
                        width: "50%",
                        textAlign: "center",
                    }}
                >
                    Sweep Safe certified
				</Typography>
            </Grid>


            <Grid
                container
                direction="column"
                xs={12}
                alignItems="center"
                style={{ width: "70%", marginTop: "20px" }}
            >
                <Typography variant="h5" style={{ color: "#4a4a4a", display: "flex" }}>
                    History
				</Typography>

                <Typography
                    style={{
                        color: "#4a4a4a",
                        padding: "20px",
                        width: "70%",
                        textAlign: "center",
                    }}
                >
                    <i style={{ fontSize: "18px", fontFamily: "Raleway" }}>
                        Cleaning Chimneys in Waterford City and County since 1984.
					</i>
                    <hr
                        className={classes.divider}
                        style={{ borderTop: "2px solid #4a4a4a" }}
                    />
					With over 31 years service with Waterford County Fire service
				</Typography>
            </Grid>

            <IconButton className={classes.expandButton} onClick={handleExpandClick}>
                <ExpandMore fontSize="large" />
            </IconButton>
        </div>
    );
};
