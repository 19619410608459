import React, { FC } from "react";
import "./App.css";
import { NavbarDesktop } from "../navbar/NavbarDesktop";
import { Home } from "../sections/Home";
import { About } from "../sections/About";
import { Services } from "../sections/Services";
import { Contact } from "../sections/Contact";
import { Gallery } from "../sections/Gallery";
import { NavbarMobile } from "../navbar/NavbarMobile";
import { isMobile } from "react-device-detect";

const App: React.FC = () => {
	const ResponsiveNav: FC = () => {
		if (isMobile) {
			return <NavbarMobile />;
		} else {
			return <NavbarDesktop />;
		}
	};

	return (
		<div className="App">
			<ResponsiveNav />
			<Home />
			<About />
			<Services />
			<Gallery />
			<Contact />
		</div>
	);
};

export default App;
