import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { scroller } from "react-scroll";
import cover from "../../assets/images/cover.webp";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage: "url(" + cover + ")",
        backgroundBlendMode: "overlay",
        backgroundSize: "cover",
    },
    header: {
        fontFamily: "Raleway",
        color: "white",
        paddingTop: "20px",
    },
    subtitle: {
        fontFamily: "Raleway",
        color: "white",
        fontSize: "18px",
    },
    expandButton: {
        marginTop: "20px",
        color: "white",
        zIndex: 2,
    },
});

export const Home: React.FC = () => {
    const classes = useStyles();

    const handleExpandClick = () => {
        scroller.scrollTo("about", {
            duration: 1500,
            delay: 100,
            smooth: true,
        });
    };

    return (
        <div id="home">
            <Grid justify="center" alignItems="center" className={classes.root}>
                <Typography variant="h2" className={classes.header}>
                    Tony Power
				</Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    Chimney Cleaning
				</Typography>
                <IconButton
                    className={classes.expandButton}
                    onClick={handleExpandClick}
                >
                    <ExpandMore fontSize="large" />
                </IconButton>
            </Grid>
        </div>
    );
};
